import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={"https://www.pngall.com/wp-content/uploads/2018/04/Under-Construction-PNG-Image-File.png"} className="App-logo" alt="logo" />
        <p>
          <i className="fa-solid fa-person-digging resizer"></i>{" "}
          <i className="fa-solid fa-truck resizer"></i>
        </p>
        <a
          className="App-link"
          href="https://phillipwalter.de"
          rel="noopener noreferrer"
        >
          Go Back
        </a>
      </header>
    </div>
  );
}

export default App;
